import * as _types2 from "@near-js/types";
var _types = "default" in _types2 ? _types2.default : _types2;
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Enum = exports.Assignable = void 0;
var types_1 = _types;
Object.defineProperty(exports, "Assignable", {
  enumerable: true,
  get: function () {
    return types_1.Assignable;
  }
});
/** @hidden @module */
class Enum {
  constructor(properties) {
    if (Object.keys(properties).length !== 1) {
      throw new Error("Enum can only take single value");
    }
    Object.keys(properties).map(key => {
      this[key] = properties[key];
      this.enum = key;
    });
  }
}
exports.Enum = Enum;
export default exports;