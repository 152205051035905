import * as _providers2 from "@near-js/providers";
var _providers = "default" in _providers2 ? _providers2.default : _providers2;
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.fetchJson = void 0;
var providers_1 = _providers;
Object.defineProperty(exports, "fetchJson", {
  enumerable: true,
  get: function () {
    return providers_1.fetchJson;
  }
});
export default exports;